@tailwind base;
@tailwind components;
@tailwind utilities;
@import "leaflet/dist/leaflet.css";

@layer base{
    @font-face {
        font-family: "Dana";
        src:url("../public/fonts/Dana/woff2/DanaFaNum-Regular.woff2") format("woff2");
        font-display: swap;
    }
    @font-face {
        font-family: "Dana Medium";
        src:url("../public/fonts/Dana/woff2/DanaFaNum-Medium.woff2") format("woff2");
        font-display: swap;
    }
    @font-face {
        font-family: "Dana DemiBold";
        src:url("../public/fonts/Dana/woff2/DanaFaNum-DemiBold.woff2") format("woff2");
        font-display: swap;
    }
    @font-face {
        font-family: "Morabba Light";
        src:url("../public/fonts/Morabba/woff2/Morabba-Light.woff2") format("woff2");
        font-display: swap;
    }
    @font-face {
        font-family: "Morabba Medium";
        src:url("../public/fonts/Morabba/woff2/Morabba-Medium.woff2") format("woff2");
        font-display: swap;
    }
    @font-face {
        font-family: "Morabba Bold";
        src:url("../public/fonts/Morabba/woff2/Morabba-Bold.woff2") format("woff2");
        font-display: swap;
    }
    body {
        @apply box-border bg-white;
        @apply font-Dana ;
    }
}

@layer utilities {
    .custome-container{
        @apply mx-1 sm:mx-4 md:mx-10 lg:mx-24 box-border ;
    }
}

@layer components {
    .btn{
        @apply bg-corn-flower text-white py-2 px-2 md:px-4 rounded-lg flex gap-x-1 md:gap-x-2 items-center justify-center text-sm sm:text-base;
    }
}
