.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  cursor: pointer;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch .toggle-switch-background:hover {
  box-shadow: inset 0 0 0 2px #a9b1bf;
  transition: all 0.1s ease-in-out;
}

.toggle-switch .toggle-switch-handle:hover {
  transition: all 0.1s ease-in-out;
}

.toggle-switch-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  border-radius: 8px;
  box-shadow: inset 0 0 0 2px #ccc;
  transition: background-color 0.3s ease-in-out;
}

.toggle-switch-handle {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  background-color: #333847;
  border-radius: 20%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.toggle-switch::before {
  content: "";
  position: absolute;
  top: -25px;
  right: -35px;
  font-size: 12px;
  font-weight: bold;
  color: #aaa;
  text-shadow: 1px 1px #fff;
  transition: color 0.3s ease-in-out;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-handle {
  transform: translateX(45px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 3px #12b76a;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-background {
  background-color: #12b76a;
  box-shadow: inset 0 0 0 2px #039855;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch:before {
  content: "On";
  color: #12b76a;
  right: -15px;
}

.toggle-switch
  input[type="checkbox"]:checked
  + .toggle-switch-background
  .toggle-switch-handle {
  transform: translateX(30px);
}
